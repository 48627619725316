<template>
  <div class="m-con">
    <div class="u-container">
      <div class="m-video">

        <div class="m-video-left">
          <h2>{{ current_title }}</h2>
          <video-player controls="true" controller="true" oncontextmenu="return true" class="video-player-box"
                        ref="videoPlayer" :options="playerOptions"
                        :playsinline="true" customEventName="customstatechangedeventname" @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                        @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
                        @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)"
                        @timeupdate="onPlayerTimeupdate($event)" @canplay="onPlayerCanplay($event)"
                        @canplaythrough="onPlayerCanplaythrough($event)"
                        @statechanged="playerStateChanged($event)" @ready="playerReadied">
          </video-player>
        </div>
        <!-- <div class="m-video-right">
          <div class="m-video-right-title" @click="to_link('/center/index')">
            <span>查看学习记录>></span>
          </div>
          <div class="m-video-right-con">
          <el-scrollbar style="height: 100%;overflow-x: 0;">

          </el-scrollbar>
          </div>
        </div> -->
      </div>

      <div class="m-content">
        <el-tabs v-model="tabName">
          <el-tab-pane label="课程目录" name="mulv">
            <div class="m-video-right-list">
              <template v-if="is_list == 1">
                <div v-for="(item2,index2) in courseList" :title="item2.title" class="m-video-right-item"
                     @click="setPlayerOption(item2)"
                     :class="{'m-video-item-active':currentId == item2.id,'is-read':(currentId != item2.id) && (item2.is_look == 1)}">
                  <div class="u-flex">
                    <img style="width: 30px;height: 30px;" v-if="currentId == item2.id"
                         src="../assets/icon-video2.png"/>
                    <img style="width: 30px;height: 30px;" v-else src="../assets/icon-video.png"/>
                    <span class="m-video-right-item-text u-line-1" style="font-size: 16px;">{{ item2.title }}</span>
                  </div>
                  <span class="m-video-right-item-time" style="font-size: 16px;">{{ item2.duration }}</span>
                </div>
              </template>
              <template v-if="is_list == 2">
                <!-- 目录 -->
                <el-collapse v-model="collapseName">
                  <template v-for="(item,index) in courseList">
                    <div class="courseClass1">
                      <span>{{ item.name }}</span>
                    </div>
                    <template v-for="(item1,index1) in item.son">
                      <el-collapse-item style="font-size: 16px;" :title="item1.name" :name="item1.id">
                        <div v-for="(item2,index2) in item1.son" :title="item2.title" class="m-video-right-item"
                             @click="setPlayerOption(item2)"
                             :class="{'m-video-item-active':currentId == item2.id,'is-read':(currentId != item2.id) && (item2.is_look == 1)}"
                             style="font-size:16x;">
                          <div class="u-flex">
                            <img style="width: 30px;height: 30px;" v-if="currentId == item2.id"
                                 src="../assets/icon-video2.png"/>
                            <img style="width: 30px;height: 30px;" v-else src="../assets/icon-video.png"/>
                            <span class="m-video-right-item-text u-line-1"
                                  style="font-size:16px;">{{ item2.title }}</span>
                          </div>
                          <span class="m-video-right-item-time" style="font-size:14px;">{{ item2.duration }}</span>
                        </div>
                      </el-collapse-item>
                    </template>
                  </template>
                </el-collapse>
              </template>
            </div>
          </el-tab-pane>
<!--          <el-tab-pane label="课程介绍" name="first">
            <div class="m-content-text" v-html="content"></div>
          </el-tab-pane>
          <el-tab-pane label="讲师介绍" name="second">
            <div class="m-content-text" v-html="info.teacher_detail"></div>
          </el-tab-pane>-->

          <el-tab-pane label="讲义下载" name="third">
            <table class="table_box"  style="width: 100%;"  cellspacing="0" cellpadding="0">
              <tr v-if="downloading">
                <td colspan="2" >
                  <div style="color:#ddd;padding-right:30px;" class="">文件加载中：{{ downloadProgress }}%</div>
                </td>
              </tr>
              <tr v-for="(item,index) in kejian_list">
                <td style="width: 90%;">{{ item.title }}</td>
                <td  style="width: 15%;"><a class="m-item-btn  m-item-btn-extend" style="background-color: #1b87ef;cursor: pointer;"
                       @click="to_course(item)">下载</a></td>
              </tr>
            </table>

          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {getCourseDetail} from "@/api/index.js"
import {clickStudy, videoRecord} from "@/api/center.js"
import collapse from "../assets/collapse.js"
import 'video.js/dist/video-js.css'
import {
  videoPlayer
} from 'vue-video-player'
import {to_link} from "@/router/api.js"

var timeFun = null
var currentData = null
var timer = null;//弹窗时间

export default {
  components: {
    videoPlayer,
    collapse
  },
  data() {
    return {
      current_title: '',
      collapseName: [],
      tabName: 'mulv',
      playerOptions: {
        id: 'video',
        height: 510,
        muted: false,
        language: 'en',
        playbackRates: [1.0, 1.1, 1.2, 1.3, 1.4, 1.5],
        sources: [],
        aspectRatio: '1200:510',
        fluid: true,
        poster: "",
        controls: true,
        controller: true,
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          currentTimeDisplay: true, // 当前时间
          volumeControl: true, // 声音控制键
          progressControl: true, // 进度条
          playToggle: true, // 暂停-播放 按钮
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      currentId: '',
      info: {},
      is_list: 1,//1视频列表 2带目录的视频列表
      courseList: [],
      kejian_list: [],//课件列表
      currentItem: {},
      content: '',
      downloading: false,
      downloadProgress: 0,
    }
  },
  mounted() {
    // this.setPlayerOption()
    this.getInfo()
  },
  methods: {
    //课程详情
    to_course(item) {
      if (!item.path) {
        this.$message.error('当前课程暂无下载资源')
        return false
      }
      var that = this;
      that.downloading = true;
      var x = new XMLHttpRequest();
      x.open("GET", item.path, true);
      x.responseType = 'blob';
      x.onprogress = function (e) {
        if (e.lengthComputable) {
          var percentComplete = (e.loaded / e.total) * 100;
          // 在这里可以更新下载进度条或其他UI元素
          that.downloadProgress = percentComplete.toFixed(2)
          console.log("下载进度: " + percentComplete.toFixed(2) + "%");
        }
      };
      x.onload = function (e) {
        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = item.path
        a.download = item.title;
        a.click()
        that.downloading = false;
      }
      x.send();
    },
    to_link,
    clickTab(e) {
      console.log(e);
    },
    //记录进度
    recodeFun() {
      console.log(this.info, 'this.info');
      clickStudy({course_id: this.$route.query.id, cate_id: this.info.cate_id, course_path_id: this.currentId})
    },
    //获取详情
    async getInfo() {
      // "http://qvod.yiche.com/03bd754cvodtransbj1251489075/47496f155285890810735509939/v.f40.mp4"
      this.loading = true
      let res = await getCourseDetail({course_id: this.$route.query.id})
      console.log(res, 'res');
      setTimeout(() => {
        this.loading = false
      }, 500)
      if (res.code == 200) {
        this.info = res.data.course
        console.log(this.info, '课程详情');
        this.courseList = res.data.course_path
        this.kejian_list = res.data.kejian_list
        this.is_list = res.data.is_list
        this.recodeFun()
        if (this.courseList.length > 0) {
          console.log(this.is_list ,'this.is_list ')
          if (this.is_list == 2) {
            // 有目录
            let list = this.courseList[0]['son'][0]['son']

           // if (list.length > 0) {
              //	let item = list[0]
              let item = res.data.newCoursePath
              console.log(res.data.newCoursePath,'res.data.newCoursePath');
              if (res.data.newVedioRecord == null) {
                item = list[0]
              }
              this.currentItem = item
              this.currentItem['isInit'] = false
              this.setPlayerOption(item)
         //   }
          } else {
            //没有目录
            //let item = this.courseList[0]
            let item = res.data.newCoursePath
            console.log(res.data.newCoursePath,'res.data.newCoursePath')

            if (res.data.newVedioRecord == null) {
              item = this.courseList[0]
            }
            this.currentItem = item
            this.currentItem['isInit'] = false
            this.setPlayerOption(item)
          }

        }
      } else {
        this.$message.error(res.message)
        //this.$router.back()
      }

    },
    setPlayerOption(item) {
      this.current_title = item.title;
      if (this.currentId == item.id) return false;
      this.currentId = item.id
      this.currentItem = item
      this.currentItem['isInit'] = false

      this.playerOptions.id = String(item.id)
      this.playerOptions.title = item.title
      this.recodeFun()

      this.content = item.synopsis || ''

      let _url = item['path']
      let _url_arr = _url.split('.');
      let _houzhui = _url_arr[_url_arr.length - 1]

      if (_houzhui == 'm3u8') {
        this.playerOptions.sources[0] = {
          type: "application/x-mpegURL",
          src: item['path']
        }
      } else {
        this.playerOptions.sources[0] = {
          type: "video/mp4",
          src: item['path']
        }
      }

    },
    //记录进度
    async videoRecordTime(time) {
      console.log(this.currentItem, 'currentItem');
      console.log(this.info, 'this.info');
      let res = await videoRecord({
        course_id: this.info.id,
        course_title: this.info.name,
        path_title: this.currentItem.title,
        path_id: this.currentId,
        time: time
      })
      if (res.code == 200) {
        if (this.is_list == 2) {
          setTimeFun(this.courseList, this.currentId, time)
        } else {
          this.courseList.forEach(item => {
            if (item.id == this.currentId) {
              item.is_look = 1
              item.vedio_record = {time: time}
              return false
            }
          })
        }
      }
    },
    pay() {
      this.isPay = true
    },

    // 检测视频播放时间
    checkVideoTime(player) {
      clearInterval(timer); // 清除定时器
      player.pause(); // 暂停视频播放
      // 调用显示弹窗的方法
      this.showPopup(player);
    },

    showPopup(player) {
      // 这里添加显示弹窗的逻辑，可以是调用现有的显示弹窗的方法或自定义弹窗
      alert('请点击确定以继续播放');
      player.play();
    },

    // 开始播放
    onPlayerPlay(player) {
      console.log('开始播放', player)
      if (!this.currentItem.isInit) {
        let time = this.currentItem.vedio_record ? (this.currentItem.vedio_record.time || 0) : 0
        console.log(time, 'time');
        player.currentTime(time)
      }

      var webInfo = this.$store.state.webInfo;
      var UserInfo = this.$store.state.user_info;
      console.log(webInfo, 'webInfo');
      console.log(UserInfo, 'UserInfo')

      //判断全局是否打开
      if (webInfo.is_open == 1) {

        if (UserInfo.is_open == 1) {
          // 设置定时器，每秒检测一次播放时间
          timer = setInterval(() => {
            this.checkVideoTime(player);
          }, webInfo.open_time*1000);
        }
      }

      clearInterval(timeFun)
      this.currentItem.isInit = true
      this.videoRecordTime(player.currentTime())
      timeFun = setInterval(() => {
        let time = player.currentTime()
        this.videoRecordTime(time)
      }, 10 * 1000)
    },
    //暂停播放
    onPlayerPause(player) {
      console.log('暂停播放', player)
      clearInterval(timeFun)
      clearInterval(timer)
      this.videoRecordTime(player.currentTime())
    },
    //播放结束
    onPlayerEnded(player) {
      console.log('播放结束');
      clearInterval(timeFun)
      clearInterval(timer)
      this.videoRecordTime(player.currentTime())
    },
    //视频加载中
    onPlayerWaiting() {
    },
    //播放中
    onPlayerPlaying() {
    },
    onPlayerLoadeddata() {
    },
    onPlayerTimeupdate() {

    },
    onPlayerCanplay() {
    },
    onPlayerCanplaythrough() {
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    playerReadied(player) {
      console.log('the player is readied', player)
    },
  }
}

function setLook(data, id) {
  data.forEach(item => {
    if (item.son) {
      setLook(item.son, id)
    } else {
      if (item.id == id) {
        item.is_look = 1
      }
    }
  })
}

function setTimeFun(data, id, time) {
  console.log(data, id, time);
  data.forEach(item => {
    if (item.hasOwnProperty('son')) {
      setTimeFun(item.son, id, time)
    } else {
      if (item.id == id) {
        item.vedio_record = {time: time}
        item.is_look = 1
      }
    }
  })
}
</script>

<style lang="scss" scoped>
.table_box {

}

.table_box tr td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.m-item-btn-extend {
  background: #D6102A;
}

.m-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  height: 35px;
  background: #F8A310;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
}

.m-con {
  width: 100%;
  min-height: 600px;
  background: #F1F1F1;
  padding-bottom: 100px;

  .m-video-right-list {
    padding: 16px 16px 24px 24px;

    .m-video-right-item {
      display: flex;
      height: 36px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .m-video-right-item-text {
        // width: 160px;
        font-size: 14px;
        color: #2D2D2D;
      }

      .m-video-right-item-time {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
        font-size: 14px;
        color: #838383;
      }
    }

    .m-video-item-active {
      .m-video-right-item-text {
        color: #D6102A !important;
      }

      .m-video-right-item-time {
        color: #D6102A !important;
      }
    }
  }

  .m-video {
    display: flex;
    height: 675px;
    box-sizing: content-box;

    .m-video-left {
      h2 {
        padding: 15px 0;
      }

      width: 1200px;
      height: 675px;
    }

    .m-video-right {
      // width: 300px;
      // height: 510px;
      background: #fff;

      .m-video-right-title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
        font-size: 14px;
        color: #D6102A;
        padding-right: 24px;
        border-bottom: 1px solid #EBEBEB;
        box-sizing: border-box;

        span {
          cursor: pointer;
        }
      }

      .m-video-right-con {
        width: 300px;
        height: 460px;
      }

      .m-video-right-btn {
        width: 103px;
        height: 36px;
        background: #1790C7;
        text-align: center;
        line-height: 36px;
        color: #fff;
        font-size: 16px;
      }

      .m-video-right-list {
        padding: 16px 16px 24px 24px;

        .m-video-right-item {
          display: flex;
          height: 36px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .m-video-right-item-text {
            width: 160px;
            font-size: 14px;
            color: #2D2D2D;
          }

          .m-video-right-item-time {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
            font-size: 14px;
            color: #838383;
          }
        }

        .m-video-item-active {
          .m-video-right-item-text {
            color: #D6102A !important;
          }

          .m-video-right-item-time {
            color: #D6102A !important;
          }
        }
      }
    }
  }


  .m-content {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 100px;
    border: 1px solid #D7D7D7;
    background: #fff;
    padding: 10px 20px;
    padding-bottom: 60px;

    .m-content-text {
      font-size: 14px;
      color: #404040;
      line-height: 24px;
    }

    .m-intro {
      display: flex;

      .m-intro-img {
        width: 160px;

        img {
          width: 160px;
        }
      }

      .m-intro-con {
        width: 900px;
        font-size: 14px;
        color: #404040;
        margin-left: 60px;
      }
    }
  }
}

.courseClass1 {
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  background: #f2f2f2;
  color: #d6102a;
  font-size: 16px;
  padding-left: 16px;

  &::before {
    content: '';
    width: 3px;
    height: 25px;
    background: #42acdc;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.video-player-box {
  height: 100%;
}

.is-read {
  opacity: 0.2;
}


</style>
<style lang="scss">
.m-con {
  background: linear-gradient(to bottom, #11a6ff, #2bb0ff, #5bc2ff, #91d6ff, #cbecff, #fdffff) !important;

  .m-content {
    .el-tabs__item.is-active {
      color: #363636;
    }

    .el-tabs__item {
      font-size: 18px;
      color: #8D8D8D;
    }

    .el-tabs__item:hover {
      color: #363636;
      cursor: pointer;
    }

    .el-tabs__active-bar {
      background-color: #D6102A;
    }
  }
}

.m-con .el-scrollbar__wrap {
  overflow-x: hidden;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-1.5em, -0.75em);
}

.m-video {
  button {
    outline: none;
  }
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3 {
  width: 100%;
  max-width: 100%;
  height: 100% !important;
}

.el-collapse .el-collapse-item__header {
  font-size: 16px;
  font-weight: bold;
}
</style>
